<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="vendorTable"
          title="참여업체 목록"
          :columns="grid.columns"
          :data="grid.data"
          :editable="editable"
          :gridHeight="setheight"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :usePaging="false"
          :isExcelDown="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="vendorUserTable"
          title="도급업체 근무인원"
          tableId="vendorUserTable"
          :columns="grid2.columns"
          :data="grid2.data"
          :merge="grid2.merge"
          :editable="editable"
          :gridHeight="setheight"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          selection="multiple"
          rowKey="userInputId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && selectedVendor.vendorCd" label="추가" icon="add" @btnClicked="addselect" />
              <c-btn v-if="editable && selectedVendor.vendorCd" label="저장" icon="save" @btnClicked="saveVendorUser" />
              <c-btn v-if="editable && selectedVendor.vendorCd" label="삭제" icon="remove" @btnClicked="removerow" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='inputPeriod'">
              <c-datepicker
                :editable="editable"
                class="tableDatepicker"
                :range="true"
                :col="col"
                :props="props"
                v-model="props.row.inputPeriod"
                @datachange="datachange(props.row, col)"
              />
              <div v-if="editable">
                <q-btn-group outline class="ColumInnerBtnGroup">
                  <q-btn
                    icon="add"
                    color="green"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props)">
                    <q-tooltip>
                      투입기간 추가
                    </q-tooltip>
                  </q-btn>
                </q-btn-group>
              </div>
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'project-attend-vendor',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
    height: {
      type: [Number, String],
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체명',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
          {
            name: 'vendorClassName',
            field: 'vendorClassName',
            label: '업체분류',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'vendorTypeName',
            field: 'vendorTypeName',
            label: '업체유형',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      grid2: {
        merge: [
          { index: 0, colName: 'userId' },
          { index: 1, colName: 'userId' },
          { index: 2, colName: 'userId' },
          { index: 3, colName: 'userId' },
          { index: 4, colName: 'userId' },
          // { index: 5, colName: 'userId' },
        ],
        columns: [
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      selectedVendor: {},
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'param.updateFlag'() {
      this.getList();
    },
  },
  computed: {
    setheight() {
      if (this.height === 'auto') return '500px';
      let numHeight = Number(this.height)
      if (numHeight <= 600) {
        numHeight = 836
      }
      numHeight = numHeight - 135

      return numHeight + 'px';
    }
  },
  methods: {
    init() {
      if (this.$store.getters.user.managerFlag === 'Y') {
        this.editable = true;
      } else {
        this.editable = this.$route.meta.editable;
      }
      // api scope
      this.listUrl = selectConfig.mdm.cim.vendor.list.url;
      this.userListUrl = selectConfig.pjm.vendorUser.inputList.url;
      this.saveUrl = transactionConfig.pjm.vendorUser.save.url;
      this.deleteUrl = transactionConfig.pjm.vendorUser.delete.url;
      this.$comm.getComboItems('SAFETY_ORG_ROLE').then(roleFlag => {
        this.grid2.columns = [
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            style: 'width:130px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'sexName',
            field: 'sexName',
            label: '성별',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'projectRoleCd',
            field: 'projectRoleCd',
            valueName: 'codeName',
            label: '역할',
            align: 'center',
            style: "width:100px",
            sortable: false,
            type: 'select',
            comboItems: roleFlag,
          },
          {
            name: 'inputPeriod',
            field: 'inputPeriod',
            label: '투입기간',
            align: 'left',
            type: 'custom',
            style: 'width:200px',
            sortable: false,
          },
        ]

      })
      this.getList();
    },
    getList() {
      this.grid.data = [];
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.param.plantCd,
        vendorCd: this.$store.getters.user.innerFlag === 'N' ? this.$store.getters.user.deptCd : '',
        companyFlag: 'N',
        companyCd: this.$store.getters.user.companyCd,
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      this.$refs['vendorUserTable'].selected = [];
      this.selectedVendor = row; 
      this.$http.url = this.userListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        innerFlag: 'N',
        searchFlag: 'N',
        // managerFlag: 'N',
        deptCd: this.selectedVendor.vendorCd,
        plantCd: this.param.plantCd,
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;

        this.$_.forEach(this.grid2.data, _item => {
          if (_item.inputStartDate && _item.inputEndDate) {
            _item.inputPeriod = [_item.inputStartDate, _item.inputEndDate]
          } else {
            _item.inputPeriod = [];
          }
        })

      },);
    },
    addselect() {
      this.popupOptions.title =  this.selectedVendor.vendorName + ' 사용자 검색'; // 도급업체 사용자
      this.popupOptions.param = {
        plantCd: this.param.plantCd,
        type: 'multiple',
        isOutUserAll: true,
        vendorCd: this.selectedVendor.vendorCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid2.data, { userId: item.userId }) === -1) {
            this.grid2.data.push({
              plantCd: this.param.plantCd,
              userId: item.userId,
              deptCd: this.selectedVendor.vendorCd,
              companyCd: this.$store.getters.user.companyCd,
              userName: item.userName,
              projectRoleCd: null,
              jobName: item.jobName,
              spotName: item.spotName,
              sexName: item.sexName,
              birthDate: item.birthDate,
              mobileNo: item.mobileNo,
              email: item.email,
              userInputId: uid(),
              inputStartDate: '',
              inputEndDate: '',
              inputPeriod: [],
              editFlag: 'C',
            })
          }
        })
        // this.$http.url = this.saveUrl;
        // this.$http.type = 'POST';
        // this.$http.param = this.grid2.data;
        // this.$http.request(() => {
        //   window.getApp.$emit('APP_REQUEST_SUCCESS');
        //   this.rowClick(this.selectedVendor)
        // });
      }
    },
    saveVendorUser() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid2.data, _item => {
              _item.plantCd = this.param.plantCd;
              if (_item.inputPeriod && _item.inputPeriod.length > 0) {
                _item.inputStartDate = _item.inputPeriod[0];
                _item.inputEndDate = _item.inputPeriod[1];
              }
            })
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = this.grid2.data;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.rowClick(this.selectedVendor)
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    removerow() {
      let selectData = this.$refs['vendorUserTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, _item => {
              _item.plantCd = this.param.plantCd
            })
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getVendorUserList({ vendorCd: this.parentVendorCd })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['vendorUserTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    datachange(row) {
      if (row.editFlag !== 'C') {
        row.editFlag = 'U'
        row.chgUserId = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      this.rowIndex = props.rowIndex;
      let userInfo = { 
        plantCd: this.param.plantCd,
        userId: props.row.userId,
        deptCd: this.selectedVendor.vendorCd,
        companyCd: this.$store.getters.user.companyCd,
        userName: props.row.userName,
        projectRoleName: props.row.projectRoleName,
        jobName: props.row.jobName,
        spotName: props.row.spotName,
        sexName: props.row.sexName,
        birthDate: props.row.birthDate,
        mobileNo: props.row.mobileNo,
        email: props.row.email,
        userInputId: uid(),
        inputStartDate: '',
        inputEndDate: '',
        inputPeriod: [],
        editFlag: 'C',
        assessUserId: this.$store.getters.user.userId,
        assessUserName: this.$store.getters.user.userName,
      }
      this.grid2.data.splice(this.rowIndex, 0, userInfo)
    },
  }
};
</script>
